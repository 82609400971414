import Icons from 'common/Icons';
import React from 'react';

const UploadedImage = ({
  file = {},
  handleImagePreview = () => {},
  handleRemoveFile = () => {},
  disabled = false,
}) => (
  <div className="btn-background" onClick={() => handleImagePreview(file)}>
    <img className="background-img" src={file.url} alt="" />
    {!disabled ? (
      <button
        className="background-remove"
        onClick={(e) => handleRemoveFile(e, file)}
      >
        <Icons iconName="close" fill="#ff6081" height={30} width={30} />
      </button>
    ) : null}
  </div>
);

export default UploadedImage;
