export const REPAIR_STATUSES = {
  'Repairs instructed on': 'The repairer has been instructed',
  'Vehicle repairs estimated on': 'Your vehicle is being estimated',
  'Estimate sent to be authorised on': 'Estimate sent to be authorised',
  'Repair costs authorised on': 'The repair costs have been authorised',
  'Repairs start date': 'The repairs are due to start',
  'Repairs due for completion on': 'Your repairs are due to be completed',
  'Repairs completed on': 'Your repairs are complete',
};

export const REPAIR_STATUSES_MAPPING = {
  'Repairs instructed on': 'RepairerInstructed',
  'Vehicle repairs estimated on': 'EstimateBID',
  'Estimate sent to be authorised on': 'EstimateToEngneer',
  'Repair costs authorised on': 'AuthorisedDate',
  'Repairs start date': 'RepairStartDate',
  'Repairs due for completion on': 'EstimatedRepairCompletion',
  'Repairs completed on': 'RepairCompletionDate',
};

const keys = {
  repairerDetails: 'Repairer details',
  vehicleDetails: 'Vehicle details',
  insuranceDetails: 'Your insurance details',
  accidentDetails: 'Incident details',
};

export const response = {
  [keys.repairerDetails]: {},
};

export const LICENSE_PLATE_REGEX = /^[A-Z]{2}\d{2}[A-Z]{3}$/g;
export const LICENSE_PLATE_LENGTH = 7;
export const currentDate = new Date().toISOString().split('T')[0];
export const MIN_DATE = '1950-01-01';

export const REPAIR_STATUS_FIELDS = [
  // Timeline Statuses =====
  'RepairerInstructed',
  'EstimateToEngneer',
  'EstimateBID',
  'RepairStartDate',
  'EstimatedRepairCompletion',
  'RepairCompletionDate',

  // Repairer Details =====
  'RepairerName',
  'RepairerTelMain',
  'RepairerEmailMain',
  // 'EstimatedRecovery',
  'EstimateToEngineer',
  'AuthorisedDate',

  // Vehicle Details =====
  'CLVehicleMake',
  'CLVehicleModel',
  'CLvehicleReg',
  'VehicleDamageDetail',
  'LegallyDriveable',
  'CLVehicleWriteOff',

  // Insurance details =====
  'ClientInsuranceExcess',

  // Accident details =====
  'AccidentDetails',
  'IncidentDate',
];

export const notFoundError = `We’ve been unable to find your repair. Please contact us.`;

export const vehicleRegistrationError =
  'Please re-check your vehicle registration number. If this is correct click Continue.';

export const incidentDateError = 'Please re-check the incident date';
