import Icons from 'common/Icons';
import React from 'react';
import FileLoading from './FileLoading';

const AddNewFile = ({
  handleUpload = () => {},
  name = '',
  disabled = false,
  classes = {},
  fileLoading,
  loadedPreview,
}) => {
  const inputDisabledClass = disabled ? 'cursor-without-hand' : null;

  const { className = '', wrapperClass = '' } = classes;
  return (
    <div className="multiple-file">
      {(!fileLoading || !loadedPreview) && !disabled ? (
        <label
          htmlFor={name}
          onChange={handleUpload}
          className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center ${inputDisabledClass}`}
        >
          <input
            type="file"
            id={name}
            className={`form-control form-control-file ${className}`}
            name={name}
            disabled={disabled}
            multiple
          />

          <Icons iconName="plus" fill="#ff6081" height={40} width={40} />
        </label>
      ) : null}
      {fileLoading ? <FileLoading text="Uploading..." /> : null}
    </div>
  );
};

export default AddNewFile;
