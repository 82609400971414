import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputErrors from 'components/InputErrors';

import {
  ALLOWED_DOCUMENT_FILE_TYPES,
  ALLOWED_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES,
  ALLOWED_VIDEO_FILE_TYPES,
  FILE_TYPES,
} from 'utils/constants';
import fileUploadingValidation from 'validation/fileUploadingValidation';
import './styles.scss';
import UploadedImage from './UploadedImage';
import AddNewFile from './AddNewFile';
import UploadedVideo from './UploadedVideo';
import UploadedDocument from './UploadedDocument';
import FilePreview from './FilePreview';
import FileList from './FileList';

class UniversalFileLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputName: null,
      fileLoading: false,
      deleting: null,
      isImagePreview: false,
      validationError: null,
      isDocumentPreview: false,
      selectedFile: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fileLoading } = this.state;
    const { fieldErrors, previewLoaded, task, value } = this.props;
    const previousDocuments = this.getValueFromTask(prevProps.task);
    const currentDocuments = this.getValueFromTask();

    if (previousDocuments?.length !== currentDocuments?.length) {
      this.completeLoading();
    }

    if (prevProps.task.fetchingOne && task.fetchedOne) {
      this.clear();
    }

    if (
      prevProps.value.length === 0 &&
      (value?.length > 0 || currentDocuments?.length > 0) &&
      fileLoading
    ) {
      this.completeLoading();
    }

    if (!prevProps.previewLoaded && previewLoaded) {
      this.completeLoading();
    }

    // Clear errors if there were fieldErrors before and now aren't
    if (
      prevProps &&
      this.props &&
      prevProps.fieldErrors &&
      fieldErrors &&
      Object.keys(prevProps.fieldErrors).length > 0 &&
      Object.keys(fieldErrors).length === 0
    ) {
      this.setState({ validationError: null });
    }
  }

  defineFileExtention = (file) => {
    const fileName = file.filename || file.description || file.name;
    if (fileName) {
      const splittedFileName = fileName.split('.');
      const fileExtention = splittedFileName[splittedFileName?.length - 1];
      if (ALLOWED_FILE_TYPES.includes(fileExtention)) return fileExtention;
    }

    const fileType = file.contentType;
    if (fileType) {
      const splittedFileType = fileType.split('/');
      const fileExtention = splittedFileType[splittedFileType?.length - 1];
      if (ALLOWED_FILE_TYPES.includes(fileExtention)) return fileExtention;
    }

    return null;
  };

  getFileData = (documents = []) => {
    const formattedFiles = documents.map(
      (doc) =>
        (doc = {
          // eslint-disable-line
          id: doc.id,
          url: typeof doc.url === 'object' ? doc.url.original : doc.url,
          name: doc.filename || doc.description || doc.name,
          loading: false,
          fileExtention: this.defineFileExtention(doc),
        })
    );

    const result = {
      input: this.props.name,
      fileId: document.id,
      files: formattedFiles,
      multiple: formattedFiles.length >= 1 ? true : false,
    };

    return result;
  };

  completeLoading = () => {
    this.setState({ fileLoading: false });
  };

  loadFile = async (e) => {
    e.persist();
    this.setState({
      fileLoading: true,
    });
    const { validationError } = this.state;
    const { setFieldErrors, clearFieldError, onUploadDocumentClick } =
      this.props;
    const pictures = e.target.files;
    const inputName = e.target.name;
    const hasData = pictures && Object.keys(pictures).length;

    if (hasData) {
      let hasError;
      for (const picture in pictures) {
        if (Object.hasOwnProperty.call(pictures, picture)) {
          const element = pictures[picture];
          const pictureUploadingError = fileUploadingValidation(
            FILE_TYPES.fileUpload,
            element
          );

          if (pictureUploadingError) {
            hasError = pictureUploadingError;
            this.setState({
              validationError: pictureUploadingError,
            });

            setFieldErrors({ [inputName]: pictureUploadingError });

            return;
          }
        }
      }

      if (!hasError && validationError) {
        this.setState(
          {
            validationError: null,
          },
          () => {
            clearFieldError(inputName);
          }
        );
      }

      if (hasError) return;
    }

    this.setState({
      inputName,
      fileLoading: true,
    });
    const fileExtention = pictures[0]?.name?.split('.')[1];
    await onUploadDocumentClick(e, false, {
      fileType: FILE_TYPES.fileUpload,
      noTypeValidation: true,
      files: pictures,
      fileExtention,
    });
  };

  removeFile = async (e, fileId) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    const { deleting, inputName } = this.state;
    const { withoutAutosave, name, removeSelectedDocument } = this.props;

    if (deleting) return;

    if (!withoutAutosave) {
      this.setState({ deleting: fileId });
    }

    const questionId = inputName ? inputName : name;
    await removeSelectedDocument(questionId, fileId);
    // this.setState({ deleting: null });
  };

  clear = () => {
    this.setState({ deleting: null });
  };

  getValueFromTask = (incomingTask) => {
    let result = null;
    const { task: propsTask, id } = this.props;
    const task = incomingTask ? incomingTask : propsTask;
    const taskAnswers = task?.data?.answers;
    if (taskAnswers?.length) {
      const currentQuestion = taskAnswers.find(
        (answer) => Number(answer?.questionId) === Number(id)
      );
      if (currentQuestion?.documents?.length)
        result = currentQuestion.documents;
    }

    return result;
  };

  handleImagePreview = ({ file }) => {
    this.setState((prev) => ({
      isImagePreview: !prev.isImagePreview,
      selectedFile: file,
      isDocumentPreview: false,
    }));
  };

  displayFileByType = ({ file = {}, disabled }) => {
    const { wrapperClass, name } = this.props;
    const { deleting, inputName } = this.state;
    const { fileExtention } = file;
    const questionId = inputName ? inputName : name;

    if (!fileExtention) return null;

    const uploadedFileProps = {
      file,
      handleRemoveFile: (e, file) => this.removeFile(e, file.id),
      disabled,
      deleting,
      wrapperClass,
      name: questionId,
    };

    if (ALLOWED_IMAGE_FILE_TYPES.includes(fileExtention)) {
      return (
        <UploadedImage
          {...uploadedFileProps}
          handleImagePreview={(file) => this.handleImagePreview({ file })}
        />
      );
    } else if (ALLOWED_VIDEO_FILE_TYPES.includes(fileExtention)) {
      return <UploadedVideo {...uploadedFileProps} />;
    } else if (ALLOWED_DOCUMENT_FILE_TYPES.includes(fileExtention)) {
      return (
        <UploadedDocument
          {...uploadedFileProps}
          handleDocumentPreview={(file) => {
            this.setState((prev) => ({
              ...prev,
              isDocumentPreview:
                file?.id === prev.selectedFile?.id
                  ? !prev.isDocumentPreview
                  : true,
              selectedFile: file,
            }));
          }}
        />
      );
    }

    // TODO handle case when extention is not in the allowed extentions list
    console.log('No file extention recognised', fileExtention);
    return null;
  };

  render() {
    const {
      className,
      wrapperClass,
      name,
      label,
      disabled,
      style,
      newDesign,
      document,
      value,
    } = this.props;
    const { previews, hidden } = document;
    const {
      fileLoading,
      deleting,
      validationError,
      isDocumentPreview,
      isImagePreview,
      selectedFile,
    } = this.state;

    let loadedPreview;
    let questionAnswerDocument = this.getValueFromTask();

    if (questionAnswerDocument?.length) {
      const filteredAnswers = questionAnswerDocument.filter(
        (v) => !hidden.includes(v.id)
      );

      loadedPreview = this.getFileData(filteredAnswers);
    } else if (!questionAnswerDocument && value?.length) {
      loadedPreview = this.getFileData(value);
    } else {
      loadedPreview = previews.find(
        (prev) => prev.input === name && prev.file && !hidden.includes(prev.id)
      );
    }

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignLabel = newDesign ? 'new-design-file-upload-label' : null;

    const filePreviewProps = {
      file: selectedFile,
      isDocumentPreview,
      // isMobile,
      isImagePreview,
      handleImagePreview: this.handleImagePreview,
    };

    const fileListProps = {
      loadedPreview,
      disabled,
      deleting,
      wrapperClass,
      displayFileByType: this.displayFileByType,
    };

    return (
      <Fragment>
        <div
          className={`form-control-multiplefile-container ${newDesignStyles}`}
          style={style}
        >
          <span className={`form-control-file-label ${newDesignLabel}`}>
            {label}
          </span>
          <div className="file-loader-multiple flex-wrap">
            <FileList {...fileListProps} />

            <AddNewFile
              handleUpload={this.loadFile}
              disabled={disabled}
              name={name}
              classes={{ className, wrapperClass }}
              fileLoading={fileLoading}
              loadedPreview={loadedPreview}
            />

            <FilePreview {...filePreviewProps} file={selectedFile} />
          </div>
          {validationError ? <InputErrors errors={[validationError]} /> : null}
        </div>
      </Fragment>
    );
  }
}

UniversalFileLoader.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadDocumentClick: PropTypes.func.isRequired,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  removeSelectedDocument: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  previewLoaded: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
};

UniversalFileLoader.defaultProps = {
  className: '',
  wrapperClass: null,
  name: '',
  document: {},
  removeSelectedDocument: null,
  label: '',
  value: [],
  disabled: false,
  previewLoaded: false,
  withoutAutosave: false,
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps, {})(UniversalFileLoader);
