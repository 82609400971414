const FileStatus = ({ file, deleting }) => (
  <>
    {file && !file?.loading && file?.id !== deleting && (
      <span className="form-control-file-sublabel">{file.name}</span>
    )}
    {file?.id === deleting && (
      <span className="form-control-file-sublabel">Deleting...</span>
    )}
  </>
);

export default FileStatus;
