/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  SET_TASK_GROUPS,
  COMPLETE_TASK_START,
  COMPLETE_TASK_FINISH,
  FETCH_FORM_TYPES_START,
  FETCH_FORM_TYPES_FINISH,
  SET_FORM_ANSWER,
  CLEAR_TASK_ANSWERS,
  CLEAR_TASK_DATA,
  SET_SUGGESTION_PEOPLE_ORGANIZATIONS,
  SET_SUGGESTION_PERSON_OR_ORGANIZATION,
} from 'actions/types';

const initialState = {
  groups: [],
  answers: [],
  completing: false,
  suggestions: [],
};

const taskRestTypes = restTypes('task');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchOneStart,
  fetchOneFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = taskRestTypes;

const task = (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
        ...action.payload,
      };

    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload.result,
        pageCurrent: action.payload.meta && action.payload.meta.currentPage,
        pagesTotal: action.payload.meta && action.payload.meta.totalPages,
      };

    case fetchOneStart:
      const data = action.payload ?? {};
      return {
        ...state,
        ...restStates.fetchOneStart,
        ...data,
      };

    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        data: action.payload,
      };

    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
        groups: [],
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
        ...action.payload,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
        ...action.payload,
      };

    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };

    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
      };

    case SET_TASK_GROUPS:
      return {
        ...state,
        groups:
          action.payload && action.payload?.length
            ? action.payload
            : state.groups,
      };

    case COMPLETE_TASK_START:
      return {
        ...state,
        completing: true,
      };

    case COMPLETE_TASK_FINISH:
      return {
        ...state,
        completing: false,
        completed: action.payload,
      };

    case FETCH_FORM_TYPES_START:
      return {
        ...state,
      };

    case FETCH_FORM_TYPES_FINISH:
      return {
        ...state,
        types: action.payload,
      };

    case SET_FORM_ANSWER:
      return {
        ...state,
        answers:
          state.answers.length > 0 &&
          state.answers.find(
            (answ) => answ.questionId === action.payload.questionId
          )
            ? state.answers.map((answ) => {
                if (answ.questionId === action.payload.questionId) {
                  return {
                    ...answ,
                    value: action.payload.value,
                    craftyclicks_object: action.payload.craftyclicks_object,
                  };
                }
                return answ;
              })
            : [action.payload],
      };

    case CLEAR_TASK_ANSWERS:
      return {
        ...state,
        answers: [],
      };

    case CLEAR_TASK_DATA:
      return {
        ...state,
        data: {},
        groups: [],
        answers: [],
      };

    case SET_SUGGESTION_PEOPLE_ORGANIZATIONS:
      return {
        ...state,
        suggestions: action.payload.contactables,
      };

    case SET_SUGGESTION_PERSON_OR_ORGANIZATION:
      return {
        ...state,
        oneSuggestion: action.payload,
      };

    default:
      return state;
  }
};

export default task;
