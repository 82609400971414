import React from 'react';
import PDFViewer from 'components/PDFViewer';
import GlobalPopup from 'components/PopupGlobal';

const FilePreview = ({
  file,
  isDocumentPreview,
  isMobile,
  isImagePreview,
  handleImagePreview,
}) => {
  return (
    <>
      {isDocumentPreview ? (
        <PDFViewer
          url={file.url}
          classWrap={`pdf-single col-xs-12 show`}
          classContent="mob-preview"
          navClass=""
          fileType={file.fileExtention}
          isMobile={isMobile}
          iFrameClass={`media-upload-content-preview`}
        />
      ) : null}

      {isImagePreview ? (
        <GlobalPopup
          isOpen={isImagePreview}
          onBackdropClick={() => handleImagePreview({ file: null })}
          content={
            <div className="modal-content">
              <img className="modal-img" src={file.url} alt="" />
              <span className="modalClose-btn">X</span>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default FilePreview;
