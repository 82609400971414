import React from 'react';
import FileStatus from './FileStatus';
import FileLoading from './FileLoading';

const FileList = ({
  loadedPreview,
  disabled,
  deleting,
  wrapperClass,
  displayFileByType,
}) => {
  if (!loadedPreview?.files?.length) return null;

  return loadedPreview.files.map((file) => (
    <div
      key={file.id}
      className="upload-form multiple-file flex direction-column items-center justify-start"
    >
      <div
        className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center`}
      >
        {/* Display a file */}
        {file && !file.loading && file.id !== deleting
          ? displayFileByType({ file, disabled })
          : null}
        {file.id === deleting ? <FileLoading /> : null}
      </div>

      <FileStatus file={file} deleting={deleting} />
    </div>
  ));
};

export default FileList;
