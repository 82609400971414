import Icons from 'common/Icons';
import React from 'react';

const UploadedVideo = ({
  file = {},
  handleRemoveFile = () => {},
  disabled = false,
  deleting = false,
}) => {
  const deletingClassName = deleting ? 'deleting' : '';

  return (
    <div className={`btn-background ${deletingClassName}`}>
      {deleting && <div className="loader white absolute" />}

      <video src={file.url} controls className="video-content" />

      {!disabled && (
        <button
          className="background-remove"
          onClick={(e) => handleRemoveFile(e, file)}
        >
          {!deleting && (
            <Icons iconName="close" fill="#ff6081" height={30} width={30} />
          )}
        </button>
      )}
    </div>
  );
};

export default UploadedVideo;
