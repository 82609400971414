import { apiPathSecond } from 'utils/paths';
import {
  currentDate,
  LICENSE_PLATE_REGEX,
  MIN_DATE,
  REPAIR_STATUS_FIELDS,
  REPAIR_STATUSES_MAPPING,
} from './constants';

export const repairStatusUrl = (license, date) =>
  // `${apiPathSecond}/status_page/claims?search_by[CLvehicleReg]=${license}`;
  `${apiPathSecond}/status_page/claims?search_by[CLvehicleReg]=${license}&search_by[IncidentDate]=${date} 00:00:00.0000000`;

export const findNewest = (arr) => {
  let res = arr[0];

  for (const el of arr) {
    if (el['updated_at'] > res['updated_at']) res = el;
  }

  return res;
};

export const constructRepairStatusUrl = (license, date) => {
  let baseUrl = `${repairStatusUrl(license, date)}`;
  let filters = REPAIR_STATUS_FIELDS.map((f) => `&filter_by[]=${f}`);

  return `${baseUrl}${filters.join('')}`;
};

export const fieldsMapping = {
  'Progress Status': {
    ...REPAIR_STATUSES_MAPPING,
  },
  'Repairer details': {
    'Repairer company name': 'RepairerName',
    'Repairer telephone number': 'RepairerTelMain',
    'Repairer email contact': 'RepairerEmailMain',
    'Estimate arranged date': 'EstimateBID',
    // 'Estimate costs sent to engineer to authorise': 'EstimatedRecovery',
    'Estimate sent to be authorised on': 'EstimateToEngneer',
    'Repair costs authorised on': 'AuthorisedDate',
    'Repair start': 'RepairStartDate',
    'Repairs due to be complete by': 'EstimatedRepairCompletion',
    'Repairs completed on': 'RepairCompletionDate',
  },
  'Vehicle details': {
    Make: 'CLVehicleMake',
    Model: 'CLVehicleModel',
    'Vehicle registration': 'CLvehicleReg',
    'Vehicle damage': 'VehicleDamageDetail',
    'Can the vehicle be driven?': 'LegallyDriveable',
    'Is the vehicle a write off?': 'CLVehicleWriteOff',
  },
  'Your insurance details': {
    'Policy excess': 'ClientInsuranceExcess',
  },
  'Incident details': {
    'Incident description': 'AccidentDetails',
    'Date of incident': 'IncidentDate',
  },
};

export const dateFields = [
  'Date of incident',
  'Estimate arranged date',
  'Estimate sent to be authorised on',
  'Repair costs authorised on',
  'Repair start',
  'Repairs due to be complete by',
  'Repairs completed on',
];

export const costFields = [
  // 'Estimate costs sent to engineer to authorise',
  'Policy excess',
];

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

function parseDate(dateString) {
  // Manually extract date parts using regex
  const match = dateString
    .trim()
    .replace(/\s+/g, ' ')
    .match(/(\w{3}) (\d{1,2}) (\d{4}) (\d{1,2}):(\d{2})(AM|PM)/);

  if (!match) return 'Invalid Date';

  // eslint-disable-next-line
  const [_, monthStr, day, year, hours, minutes, period] = match;

  // Create a mapping for the month abbreviations to month numbers
  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  // Convert hours to 24-hour format
  let hours24 = parseInt(hours, 10);
  if (period === 'PM' && hours24 !== 12) {
    hours24 += 12;
  } else if (period === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  // Create the Date object
  const date = new Date(year, months[monthStr], day, hours24, minutes);

  return isNaN(date.getTime())
    ? dateString
    : date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
}

export const formatDateValue = (date) => {
  // if (!date) return '';

  if (!isDateValid(date)) return date;

  let formattedDate = new Date(date);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  const resultDate = new Intl.DateTimeFormat('en-GB', options).format(
    formattedDate
  );

  return resultDate;
};

export const processDate = (date) => {
  if (!date) return '';

  if (isDateValid(date)) {
    return formatDateValue(date);
  } else {
    return parseDate(date);
  }
};

const formatCosts = (n) =>
  Number(n).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const processFieldValue = (fieldName, fieldValue) => {
  if (!fieldValue) return '';

  let processedValue = fieldValue;

  if (dateFields.includes(fieldName)) {
    processedValue = processDate(fieldValue, fieldName);
  } else if (costFields.includes(fieldName)) {
    processedValue = !isNaN(fieldValue)
      ? `£ ${formatCosts(fieldValue)}`
      : `£ ${fieldValue}`;
  } else if (fieldName === 'Is the vehicle a write off?') {
    processedValue = fieldValue === 'Y' || fieldValue === 'Yes' ? 'Yes' : 'No';
  }

  return processedValue;
};

export const mapResult = (data) => {
  if (!data) return null;

  const result = {};

  for (const section in fieldsMapping) {
    if (Object.prototype.hasOwnProperty.call(fieldsMapping, section)) {
      const sectionFields = fieldsMapping[section];
      result[section] = {};

      for (const fieldKey in sectionFields) {
        if (Object.prototype.hasOwnProperty.call(sectionFields, fieldKey)) {
          const fieldValue = sectionFields[fieldKey];
          const targetDetail = data.find((item) => item.name === fieldValue);

          result[section][fieldKey] = processFieldValue(
            fieldKey,
            targetDetail?.value
          );
        }
      }
    }
  }

  return result;
};

const defineWriteOffStatus = (
  progressStatus = 'Estimate sent to be authorised on'
) => {
  switch (progressStatus) {
    case 'Repairs instructed on':
      return 'Repairs instructed on';
    case 'Vehicle repairs estimated on':
      return 'Vehicle repairs estimated on';
    case 'Estimate sent to be authorised on':
      return 'Estimate sent to be authorised on';
    case 'Repair costs authorised on':
      return 'Estimate sent to be authorised on';

    default:
      return progressStatus;
  }
};

export const defineRepairProgress = (mappedFields) => {
  let progressStatus = '';
  let isWriteOff = false;

  if (
    mappedFields['Progress Status']['Repairs instructed on'] &&
    // mappedFields['Repairer details']['Repairer company name'] &&
    !mappedFields['Repairer details']['Estimate arranged date'] &&
    !mappedFields['Repairer details']['Estimate sent to be authorised on'] &&
    !mappedFields['Repairer details']['Repair costs authorised on'] &&
    !mappedFields['Repairer details']['Repair start'] &&
    !mappedFields['Repairer details']['Repairs due to be complete by'] &&
    !mappedFields['Repairer details']['Repairs completed on']
  ) {
    progressStatus = 'Repairs instructed on';
  } else if (
    mappedFields['Repairer details']['Estimate arranged date'] &&
    !mappedFields['Repairer details']['Estimate sent to be authorised on'] &&
    !mappedFields['Repairer details']['Repair costs authorised on'] &&
    !mappedFields['Repairer details']['Repair start'] &&
    !mappedFields['Repairer details']['Repairs due to be complete by'] &&
    !mappedFields['Repairer details']['Repairs completed on']
  ) {
    progressStatus = 'Vehicle repairs estimated on';
  } else if (
    mappedFields['Repairer details']['Estimate sent to be authorised on'] &&
    !mappedFields['Repairer details']['Repair costs authorised on'] &&
    !mappedFields['Repairer details']['Repair start'] &&
    !mappedFields['Repairer details']['Repairs due to be complete by'] &&
    !mappedFields['Repairer details']['Repairs completed on']
  ) {
    progressStatus = 'Estimate sent to be authorised on';
  } else if (
    mappedFields['Repairer details']['Repair costs authorised on'] &&
    !mappedFields['Repairer details']['Repair start'] &&
    !mappedFields['Repairer details']['Repairs completed on'] &&
    !mappedFields['Repairer details']['Repairs due to be complete by']
  ) {
    progressStatus = 'Repair costs authorised on';
  } else if (
    mappedFields['Repairer details']['Repair start'] &&
    !mappedFields['Repairer details']['Repairs completed on'] &&
    !mappedFields['Repairer details']['Repairs due to be complete by']
    // (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
    //   mappedFields['Vehicle details']['Write Off?'] === 'No')
  ) {
    progressStatus = 'Repairs start date';
  } else if (
    mappedFields['Repairer details']['Repairs due to be complete by'] &&
    !mappedFields['Repairer details']['Repairs completed on']
    // (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
    //   mappedFields['Vehicle details']['Write Off?'] === 'No')
  ) {
    progressStatus = 'Repairs due for completion on';
  } else if (
    mappedFields['Repairer details']['Repairs completed on']
    // (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
    //   mappedFields['Vehicle details']['Write Off?'] === 'No')
  ) {
    progressStatus = 'Repairs completed on';
  }

  if (
    (progressStatus === 'Repair costs authorised on' ||
      progressStatus === 'Vehicle repairs estimated on' ||
      progressStatus === 'Repairs instructed on' ||
      progressStatus === 'Estimate sent to be authorised on' ||
      progressStatus === 'Repairs start date' ||
      progressStatus === 'Repairs due for completion on' ||
      progressStatus === 'Repairs completed on') &&
    (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'Yes' ||
      mappedFields['Vehicle details']['Write Off?'] === 'Yes')
  ) {
    isWriteOff = true;
    progressStatus = defineWriteOffStatus(progressStatus);
  }

  return { progressStatus, isWriteOff };
};
// export const defineRepairProgress = (mappedFields) => {
//   let progressStatus = '';
//   let isWriteOff = false;

//   if (
//     mappedFields['Progress Status']['Repairs instructed on'] &&
//     // mappedFields['Repairer details']['Repairer company name'] &&
//     !mappedFields['Repairer details']['Estimate arranged date'] &&
//     !mappedFields['Repairer details']['Repair costs authorised on'] &&
//     !mappedFields['Repairer details']['Repair start'] &&
//     !mappedFields['Repairer details']['Repairs due to be complete by'] &&
//     !mappedFields['Repairer details']['Repairs completed on']
//   ) {
//     progressStatus = 'Repairs instructed on';
//   } else if (
//     mappedFields['Repairer details']['Estimate arranged date'] &&
//     !mappedFields['Repairer details']['Repair costs authorised on'] &&
//     !mappedFields['Repairer details']['Repair start'] &&
//     !mappedFields['Repairer details']['Repairs due to be complete by'] &&
//     !mappedFields['Repairer details']['Repairs completed on']
//   ) {
//     progressStatus = 'Vehicle repairs estimated on';
//   } else if (
//     mappedFields['Repairer details']['Repair costs authorised on'] &&
//     !mappedFields['Repairer details']['Repair start'] &&
//     !mappedFields['Repairer details']['Repairs completed on'] &&
//     !mappedFields['Repairer details']['Repairs due to be complete by']
//   ) {
//     progressStatus = 'Repair costs authorised on';
//   } else if (
//     mappedFields['Repairer details']['Repair start'] &&
//     !mappedFields['Repairer details']['Repairs completed on'] &&
//     !mappedFields['Repairer details']['Repairs due to be complete by'] &&
//     (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
//       mappedFields['Vehicle details']['Write Off?'] === 'No')
//   ) {
//     progressStatus = 'Repairs start date';
//   } else if (
//     mappedFields['Repairer details']['Repairs due to be complete by'] &&
//     !mappedFields['Repairer details']['Repairs completed on'] &&
//     (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
//       mappedFields['Vehicle details']['Write Off?'] === 'No')
//   ) {
//     progressStatus = 'Repairs due for completion on';
//   } else if (
//     mappedFields['Repairer details']['Repairs completed on'] &&
//     (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'No' ||
//       mappedFields['Vehicle details']['Write Off?'] === 'No')
//   ) {
//     progressStatus = 'Repairs completed on';
//   }

//   if (
//     progressStatus === 'Repair costs authorised on' &&
//     (mappedFields['Vehicle details']['Is the vehicle a write off?'] === 'Yes' ||
//       mappedFields['Vehicle details']['Write Off?'] === 'Yes')
//   ) {
//     isWriteOff = true;
//     progressStatus = 'Vehicle repairs estimated on';
//   }

//   return { progressStatus, isWriteOff };
// };

export const getEstimatedCompletionDate = (status, data) => {
  if (!data) return '';

  const targetDetail = data.find((item) => item.name === status);

  return targetDetail?.value ?? '';
};

export const validateLicensePlate = (licensePlate) => {
  if (!licensePlate) return false;

  return licensePlate.match(LICENSE_PLATE_REGEX);
};

export const validateDate = (incidentDate) =>
  incidentDate >= MIN_DATE && incidentDate <= currentDate;

export const isResultRelevant = (claim) => {
  if (!claim) return false;

  const details = claim.details?.details ?? claim.details;

  const isRelevant = details.find(
    (d) => d.name === REPAIR_STATUSES_MAPPING['Repairs instructed on']
  );

  return isRelevant;
};
