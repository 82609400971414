import React from 'react';
import DocumentPlaceholder from '../MediaUpload/components/DocumentPlaceholder';

const UploadedDocument = ({
  file = {},
  handleDocumentPreview = () => {},
  handleRemoveFile = () => {},
  disabled = false,
}) => (
  <div className="media-upload-content">
    <DocumentPlaceholder
      onClick={() => handleDocumentPreview(file)}
      fileName={file.name}
      removeFile={(e) => handleRemoveFile(e, file)}
      disabled={disabled}
    />
  </div>
);

export default UploadedDocument;
