import React from 'react';
const SPINNER_LENGTH = 12;

const FileLoading = ({ text }) => (
  <>
    <div
      className="flex justify-end items-center direction-column"
      style={{ width: 100, height: 100, margin: '5px 0 5px 15px' }}
    >
      <div
        className="flex justify-center items-center"
        style={{ width: 100, height: 100 }}
      >
        <div className="lds-spinner">
          {Array.from(Array(SPINNER_LENGTH), (_, i) => (
            <div key={i} />
          ))}
        </div>
      </div>
    </div>
    {text ? (
      <p
        style={{ marginTop: 0 }}
        className="form-control-file-sublabel text-center"
      >
        {text}
      </p>
    ) : null}
  </>
);

export default FileLoading;
